<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          :id="`popover-create`"
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="code"
                type="text"
                label="Descrizione"
                v-model="filter.byAttribute.descrizione"
                placeholder="Inserisci una descrizione"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="code"
                type="text"
                label="Causale"
                v-model="filter.byAttribute.codice"
                placeholder="Inserisci una causale"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <cash-references-table
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      @destroy="onDestroy"
      :ref="tableRef"
      :onlyActions="['edit', 'destroy']"
      sortField="codice"
    ></cash-references-table>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import CashReferencesTable from "@/components/tables/CashReference.vue";
import BaseInput from "@/components/form/BaseInput";
import { mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "cash_reference",
      resource: "causali_prima_nota",
      tableRef: "CashReferenceTableRef",
      filterName: "filterCashReferences",
      filter: this.initFilter(),
      fields: [
        {
          key: "descrizione",
          label: this.getDictionary("title", "cash_reference"),
          sortable: true,
          sortKey: "descrizione",
        },
        {
          key: "codice",
          label: this.getDictionary("code", "cash_reference"),
          sortable: true,
          sortKey: "codice",
        },
        {
          key: "causale_automaticamente_collegata",
          formatter: (value) =>
            value ? `${value.codice} - ${value.descrizione}` : "-",
          label: "Causale autom. collegata",
        },
        {
          key: "tipologia",
          label: this.getDictionary("reference_type", "cash_reference"),
          formatter: (value) => {
            switch (value) {
              case 0:
                return "Standard";
              case 1:
                return "Acconto";
              case 2:
                return "Sospeso";
              case 3:
                return "Recupero Acconto";
              case 4:
                return "Recupero sospeso";
              default:
                return "Tipo sconosciuto";
            }
          },
          sortable: true,
          sortKey: "byAttribute.tipologia",
        },
        {
          key: "tipo_di_movimento",
          label: this.getDictionary("entry_type", "cash_reference"),
          formatter: (value) => (value ? "Uscita" : "Entrata"),
          sortable: true,
          sortKey: "byAttribute.tipo_di_movimento",
        },
        {
          key: "include_produttore",
          label: this.getDictionary("has_broker", "cash_reference"),
          formatter: (value) => (value ? "Sì" : "No"),
          sortable: true,
          sortKey: "byAttribute.include_produttore",
        },
        {
          key: "include_compagnia",
          label: this.getDictionary("has_company", "cash_reference"),
          formatter: (value) => (value ? "Sì" : "No"),
          sortable: true,
          sortKey: "byAttribute.include_compagnia",
        },
        {
          key: "conto",
          label: this.getDictionary("default_treasury", "cash_reference"),
          formatter: (value) => `${value.codice} - ${value.descrizione}`,
          sortable: true,
          sortKey: "byConto.descrizione",
        },
      ],
    };
  },
  components: {
    CashReferencesTable,
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          codice: null,
          descrizione: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Causale eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
